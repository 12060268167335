img.harvetimg {
    width: 108px;
    height: auto;
  
}
iframe#kiwi-big-iframe-wrapper {
    bottom: 102px !important;
    }
    @media screen and (max-width:767px) {
        iframe#kiwi-big-iframe-wrapper {
            bottom: 90px !important;
            right: 0px!important;
            }
    }
    
.text-left.harvestd {
    position: absolute;
    left: 13%;
    border: 19%;
    bottom: 27%;
}
.text-left.harvcls1 {
    position: absolute;
    left: 13%;
    border: 19%;
    top:7%;
}
@media (max-width: 500px) {
            
    .text-left.harvcls1 {
        position: absolute;
    left: 13%;
    border: 19%;
    top: 55%;
    }
    
}
body.platform-ios.platform-cordova.platform-webview {
    text-rendering: auto !important;
}
body{
    color:#333;
}
.desktopheader{display:none;}
ion-content {
    // overwrite inline styles
    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
/*    &::-webkit-scrollbar {
      display: none;
    }*/
  }

.loginbox.desktop{
    margin:0px auto;
    max-width:400px;
}  

.bannerfullwidth{
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:-20px;
    margin-top:60px;
    }
ion-menu {ion-content,ion-item,ion-list,ion-footer{
    --background:var(--ion-color-primary); 
    background:var(--ion-color-primary)!important; 
  }
  }

  .sidemenucontent {ion-label,ion-icon{
    color:#ffffff!important;
  
  }

  ion-item{
      ion-label{
          padding:0px;
          padding-left:15px;
      }
  }
 
  }  
  .art{
    //   height: 600px;
    //   width:800px;
    //    margin:0 auto;
    //   color: #000000!important;
    //   background: #81444e!important;
      position: absolute;
      width: 600px;
      height: 400px;
      z-index: 15;
      top: 28%;
      left: 50%;
      margin: -59px 0 0 -275px;
      background: #b62525;;
      overflow:auto;

      
  }
/*
ion-popover{
    ion-backdrop{
    opacity: 0.5 !important;
    }
    .popover-wrapper{
    .popover-content{
    width:80vw;
    max-width:500px;
    }
    }
    }*/
    @media (max-width: 500px) {
            
        div#topcategory img {
            height: 16px!important;
        }
        
    }
    @media (max-width: 500px) {
            
        img.segmenticonimg {
            height: 16px!important;
            margin-top: 6px;
        }
        
    }
    @media (max-width: 500px) {
        ion-button.buynbut {
            font-size: 10px !important;
            /* padding: 3px; */
            padding: 3px !important;
            margin: 1px 0px;
            height: 32px;
      }
       
        }
       
        
        @media (max-width: 500px) {
            ion-button.outofs {
                height: 2.7em!important;
                font-size: 10px!important;
            }
            }
            @media (max-width: 500px) {ion-button.seeallb {
                font-size: 14px;
            }}
    @media (min-width: 950px) {
        ion-app {
            position:relative;
   
/*          max-width: 1150px;
          margin: 0px auto;
    
          border-radius: 10px;*/
          
        }
      

        #main-content{
            margin-top:100px;

        }

        .desktopheader{
            display: unset;
        }

        ion-content{
            --padding-start:15%!important;
            --padding-end:15%!important;
        }
        ion-header,ion-footer,.cartshortsummary{
            padding-left:15%!important;
            padding-right:15%!important;
        }
      
        .seperatorhr{
            width:unset!important;
        }
      

      }

 .inverttowhitecolor{
    filter: brightness(0) invert(1);
    opacity:1;
 } 
ion-content:not(.nopadding){
  --color: #333333;
  --padding-start: var(--ion-padding, 10px);
  --padding-end: var(--ion-padding, 10px);
  --padding-top: var(--ion-padding, 10px);
  --padding-bottom: var(--ion-padding, 10px);
  padding-left: var(--ion-padding, 10px);
  padding-right: var(--ion-padding, 10px);
  padding-top: var(--ion-padding, 10px);
  padding-bottom: var(--ion-padding, 10px);
}

ion-back-button{
    height:32px!important;
}

ion-content.nopadding{
    --color: #333333;
    --padding-start: var(--ion-padding, 0px);
    --padding-end: var(--ion-padding, 0px);
    --padding-top: var(--ion-padding, 0px);
    --padding-bottom: var(--ion-padding, 0px);
    padding-left: var(--ion-padding, 0px);
    padding-right: var(--ion-padding, 0px);
    padding-top: var(--ion-padding, 0px);
    padding-bottom: var(--ion-padding, 0px);
  }

ion-app,ion-footer{ 
    --ion-background-color:#ffffff;
}


ion-footer{
background-color: #ffffff; 
padding:15px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}
 
.footer-md:before{
    background-image: none!important;
} 

ion-header:after {
    background-image: none!important;
    }
 

ion-row.sbuttons
 {
     ion-col{
    padding-right:0px;
     }
 }
 
 
    .btn-success {
        background-color: #2ed8b6;
        border-color: #2ed8b6;
        color: #fff;
        cursor: pointer;
        transition: all ease-in 0.3s;
    }
    .btn-danger {
      background-color: #FF5370;
      border-color: #FF5370;
      color: #fff;
      cursor: pointer;
      transition: all ease-in 0.3s;
    }
    
    .btn {
      border-radius: 2px;
      text-transform: capitalize;
      font-size: 15px;
      padding: 10px 19px;
      cursor: pointer;
    }
    .btn {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
        
     
ion-header.noredbg {
    padding:20px;
    padding-bottom: 0px;
    --min-height:80px;
    ion-toolbar{
        --min-height:80px; 
    }
    ion-searchbar{
        
        padding-left: 0px!important;
        padding-right: 0px!important;
        padding-top: 20px!important;
        
        .searchbar-input-container{ 
            border-radius: 5px;
            border: solid 2px var(--ion-color-primary);
            background-color: #f4f4f5;
            box-shadow: none!important;  
            .searchbar-input.sc-ion-searchbar-md{
                box-shadow: none!important;  
            }
        }
    } 
    ion-title{
        top:48px;
        padding:0px;
        position: absolute;
        color: var(--ion-color-primary);

        font-size: 26px;
     font-weight: bold;
    font-stretch: normal;
    font-style: normal; 
    letter-spacing: normal;
    text-align: left; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    }
    
      

    ion-toolbar ion-buttons{
        position:absolute;
        margin:0px;
        top:0px;
        left:0px;   
        ion-button{
            --padding-start: 0px!important;
            --padding-end: 0px!important;
        }
        ion-icon{
            width: 30px;
  height: 25px!important;
            color:#677fa1;
            
            }
    
          
    }


    ion-back-button {
        font-size: 24px; 
        color:#677fa1;
    }
}  


ion-header:not(.noredbg){
ion-toolbar:not(.noredbg) {
    background-color: var(--ion-color-primary);
    --background:var(--ion-color-primary);
    --color: #ffffff;
   } 
}


ion-header.desktop:not(.noredbg){
    padding-top:10px;
    padding-bottom:10px;
/*    box-shadow: 0 0 1px #aaa;*/
    ion-toolbar:not(.noredbg) {
         background-color: unset;
        --background:unset;
            --color:unset;
          
       } 
    }
    

.seperatorhr{    
    width: 120%;
    height: 6px;
    background-color: #ebf1f8;
    margin-left: -20px;
    margin-right: -37px;  
    margin-top:2px;
    margin-bottom:2px;   
}   


.seperatorhrwhite1{
height:0px;
border:solid 1px #ffffff;
}
  
 

.wrapper .otp-input:not(:last-child) {
    margin-right: 5px!important;
}
.otp-input{
    height: 45px !important;
    font-size: 24px;
    padding: 0;
    text-align: center;
    background-color:#e4e4e4;
    
  }

ion-button{
   /* font-weight:700;*/
    text-transform: none !important; 

    img {
        height: 28px;
        margin-right: 10px;
        opacity:0.8;
     }

    
}

 

ion-button.button-large{
    height: 50px;
    font-size:15px;
}

ion-button.button-outline{
    --border-width: 1px;
}

ion-button.custom1-inactive{
    /* font-weight:700;*/
     text-transform: none !important; 
     --border-width	:1px;
     --border-color	: #c7d4e6;
   /*  --background	:#f4f4f5; */
     --color	:#42608b;
     --border-radius	: 5px;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.21;
     letter-spacing: normal;
     text-align: center; 
 
     
 }

 ion-button.custom1-active{
    /* font-weight:700;*/
     text-transform: none !important; 
     --background	:#6a962c;
     --background-focused: #6a962c;
    --background-hover: #95a580;

     --border-radius	: 5px;
     --border-width	:1px;
     --color	: #ffffff;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.21;
     letter-spacing: normal;
     text-align: center; 

     img{
        filter: brightness(0) invert(1);
        opacity:1;
     }
 }

 ion-button.custom2-inactive{
    /* font-weight:700;*/
     text-transform: none !important; 
     --border-width	:1px;
     --border-color	:#c7d4e6;
     /*--background	:#f4f4f5;*/
     --color	:#42608b;
     --border-radius	: 5px;
     
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.21;
     letter-spacing: normal;
     text-align: center; 

    

 }

 ion-button.custom2-active{
    /* font-weight:700;*/
     text-transform: none !important; 
     --background	:var(--ion-color-primary);
     --background-focused: var(--ion-color-primary);
    --background-hover: #95a580;

     --border-radius	: 2px;
     --border-width	:1px;
     --color	: #ffffff;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.21;
     letter-spacing: normal;
     text-align: center; 
     img{
        filter:invert(1);
        opacity:1;
     }
 }


 ion-button.custom1r-inactive{
    /* font-weight:700;*/ 
    color: #989aa7;
     --background	:#f4f4f5; 
     --background-hover: #f4f4f5;
 }

 ion-button.custom1r-active{
    /* font-weight:700;*/  
     --background	:#6a962c;
     --background-focused: #6a962c;
    --background-hover: #6a962c; 
 }


ion-input{
    --padding-start	:0px;
/*    font-weight:400;*/
}
.native-input.sc-ion-input-md{
    font-weight:bold;
}

ion-item ion-row{
    width:100%;
}

ion-item{
    --inner-padding-end: 0px;
    --padding-start: var(--ion-padding, 0px);
    --padding-end: var(--ion-padding, 0px);
    --padding-top: var(--ion-padding, 0px);
    --padding-bottom: var(--ion-padding, 0px);
    padding-left: var(--ion-padding, 0px);
    padding-right: var(--ion-padding, 0px);
    padding-top: var(--ion-padding, 0px);
    padding-bottom: var(--ion-padding, 0px);
    --border-width: 0px 0px 0px 0px;
    --highlight-color-focused: #000;
    --highlight-color-valid: #000;
    --highlight-color-invalid: #de252d;
    --border-color: transparent;
}

ion-item ion-input:not(.noborder), ion-item ion-textarea{
/*    border-bottom:1px solid #d5d5d5;*/
border-bottom: solid 1px #e9e9eb;
}

ion-card-title{
    color: var(--ion-color-primary);
    
    font-weight:700;
    font-size:1.3em; 
}
ion-card-subtitle{
    color: var(--ion-color-primary); 
   text-transform: capitalize;
} 



/*ion-input[type="number"]{
    border: 1px solid #89959b;
    border-radius: 32px; 
    height: 100%;
    color: #777777; 
    display: inline;
    font-weight: bold;
}
*/
ion-row:not(.pincodenumber)  .native-input[type="number"]{
//    text-align: center!important;
    padding-left: 0px!important; 
} 

.plusminus{
    color:#6a6d7a;
    padding-top:2px;
}
.plusminus .c_g{
    background-color:#f4f4f5;
}
.plusminus ion-icon{
   /* width:100%;height:100%; /*color:#999999;*/
   font-size:1.3em;
   padding-top:15px;
  }
.plusminus .c_g ion-icon{
    font-size:1.2em;
}
  
.plusminus .native-input[type="number"]{
         text-align: center!important;
        padding-left: 0px!important; 
    } 
ion-row.specialnumber  {
    .native-input[type="number"],.native-input[type="date"]{
    text-align: left!important;
    padding-left: 0px!important; 
} 

}
/*
ion-datetime{
    border: 1px solid #89959b;
    border-radius: 32px;
    padding: 10px;
    color: #777777;
    font-weight: bold;
}*/

ion-datetime,ion-select{
    color:    #6a6d7a;
    
    padding: 5px;
    margin-top:7px;
    border-radius: 2px;
    border: solid 1px #e9e9eb;
 /*   background-color: #f4f4f5; */
    
}

ion-datetime{
    height:33px;
}

.select-placeholder {
    opacity:1;
    }
hr{
    height: 0px;
    /* border-width: 0; */
    /* background: #e9e9eb; */
    /* box-sizing: content-box; */
    border: solid 0.75px #e9e9eb;
}

ion-slides{
    --bullet-background	:#4a3333!important;

    
}
 
/*
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 10px;
      left: 0px;
      width:inherit!important;
  }*/

  

  ion-label{ 
    
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    
    
    /*    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #989aa7!important;*/
  }

  ion-tabs{
      ion-tab-bar{
          height:35px;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
          border-top-left-radius: 40px;
    border-top-right-radius: 40px;
        }
      ion-tab-button{
 
      ion-label{
        
      
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
           
      
      }

      ion-icon{
        font-size: 1.8em;
      }

      ion-badge{
        position: absolute;
        top: 5px;
        /* right: 26px; */
        font-size: 0.6em;
        /* --padding-start: 5px; */
        padding: 5px;
      }

    }

    ion-tab-button.tab-selected:not(.middlemenu){
        ion-label{color:#2b2727!important;}
        ion-icon{
            filter:brightness(10%);
            border-bottom: 1px solid;
        }
        img{
            filter:brightness(90%);
            // margin: 2px;

            border-bottom: 1px solid #000; 
        }
    }
   
  }
 
 
  .resultsnotfound{
      padding:20px;
    text-align: center;
    .ttext{
        
        margin:0px auto;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: 0.4px;
        text-align: center;
        color: #282c3f;
    }
  }

  .select-placeholder {
opacity:1!important;  
}


/*ion-range fix*/ 
/* ionic core changes - changing shadow DOM*/  
  .range-knob-handle .range-pin {
    transform: translate3d(0, -24px, 0) scale(1)!important; }

    :focus {
        outline: none;
        }


        app-menu-card-single{
            width:100%;
        }

        .alert-message{
          

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #6a6d7a!important;
        }


         ion-action-sheet{
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
            .action-sheet-title.sc-ion-action-sheet-md{
                padding-left:0px;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.3;
              letter-spacing: normal;
              text-align: left;
              height:auto;
              color: #bf213e;
              .action-sheet-sub-title.sc-ion-action-sheet-md{
                color: #6a6d7a;
            }
            }
           
            .action-sheet-button.sc-ion-action-sheet-md{
              border-radius: 2px;
              background-color: #e9e9eb;
              margin-bottom:10px;

              .action-sheet-button-inner.sc-ion-action-sheet-md
              {

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.75px;
  text-align: center;
  color: #6a6d7a;    justify-content: center;
              }
            } 

            .action-sheet-group.sc-ion-action-sheet-md:first-child{
                padding-left:20px;
                padding-right:20px;
                padding-bottom:20px;
                padding-top:10px;
            }

            .action-sheet-group-cancel.sc-ion-action-sheet-md{
                display: none;
            }

        }

.clickEnableCls{
    pointer-events: visible !important;
    display: flex!important;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    ion-icon{
        font-size: 1.7em;
        padding:0px!important;
    }
  }
 .mymatipspopover{
    --min-width: 250px; 
    --max-height: 348px;
    --min-height: 248px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    
 }
 
 .videoplayerpopover{
    --min-width: 300px;   
 }

.longlabel{
    text-overflow: unset !important;
    white-space: normal !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    font-size: 12px;
    color: #989aa7 !important;    padding-top: 20px;
    
 }

 .itemwithtooltip{
     .tooltip{ 
         display:flex;
         justify-content: center;
         align-items: center;
         font-size:3em;
         color: #989aa7 !important;
         border-bottom: solid 1px #e9e9eb;
         padding:0px;
         margin-bottom:5px;
     } 
 }

 .flexcenterleft{
    display:flex;
     align-items:center;
     justify-content: left;
 
 }
 .flexcentercenter{
     display:flex;
     align-items:center;
     justify-content: center;
 }

 .flexcenterright{
    display:flex;
    align-items:center;
    justify-content: flex-end;
}


h1{
   
    color: var(--ion-color-primary);
    margin-top:0px;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #bf223c;
}

/*login/register*/
/*bordered*/
.bordered {
    display: block;
    position: relative;
    background: #FFFFFF;
    padding: 15px;
    border: 1px solid #DDDDDD;
    margin-top: 20px;
  }
  .bordered:before, .bordered:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  .bordered:before {
    left: 50%;
    border: 11px solid transparent;
    border-bottom-color: #ddd;
  }
  .bordered:after {
    left: calc(50% + 1px);
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }
  .pleaseenterstring{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #797777;
  }
  .vcodestring{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    padding-top:20px;
    color: #000000;
  }


  .resendvcodestring{
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: gray;
  }

  .resendvcodestring.active{
   
    color: #cf473e;
  }
.dodclr{
  font-size: 20px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #cf473e;
}

.tabbar {
    justify-content: center;
  }
  
  .tab-button {
    max-width: 200px;
  }
  .my-custom-class-address{
    --width:80%;
    --height:75%;
  }

  .my-custom-class-mobile {
    --width:100%;
    --height:100%;
  }
  .my-custom-class-offers{
    --width:auto;
    --height:auto;
  }
  .my-custom-class-mobileoffers {
    --width:100%;
    --height:100%;
  }

  .alert-radio-label.sc-ion-alert-md {
    display: flex;
    white-space: inherit;
  }

  @media only screen and (min-width: 768px) and (min-height: 768px){
    .offers {
        --width: 600px;
        --height: 200px;
    }
    }

  